var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-default fixed-top",staticStyle:{"justify-content":"right !important"}},[_c('div',{staticClass:"container",staticStyle:{"padding-right":"7.6%","justify-content":"right !important"}},[_c('div',{staticClass:"logo"},[_c('img',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('slideInLeft'),expression:"'slideInLeft'"}],staticClass:"img-fluid",staticStyle:{"width":"132px","position":"absolute","top":"9px","left":"8%"},attrs:{"src":"/img/1x/logo1.png","alt":""}})]),(_vm.$route.name == 'checkinManual')?_c('div',{staticClass:"pasos lg",staticStyle:{"position":"fixed","left":"0","right":"0","margin":"auto","text-align":"center","width":"40%"}},[_c('ul',{staticClass:"d-flex flex-row",staticStyle:{"margin":"auto","text-align":"center","align-items":"center","justify-content":"center"}},[_c('li',{class:{ 'cursor': _vm.$store.state.stateProcess == 2 },style:(_vm.$store.state.stateProcess == 1
            ? { 'font-weight': 'bold', color: '#333' }
            : { 'font-weight': 'bold', color: 'rgb(167 167 167)' }),on:{"click":function($event){$event.preventDefault();return _vm.menuActive(1)}}},[_vm._v(" "+_vm._s(_vm.$t("app.t.text75"))+" ")]),_c('div',{staticClass:"line-separator",staticStyle:{"height":"2px","background":"rgb(138 138 138)","width":"60px","margin-left":"20px","margin-right":"20px"}}),_c('li',{style:(_vm.$store.state.stateProcess == 2
            ? { 'font-weight': 'bold', color: '#333' }
            : { 'font-weight': 'bold', color: 'rgb(167 167 167)' })},[_vm._v(" "+_vm._s(_vm.$t("app.t.text76"))+" ")]),_c('div',{staticClass:"line-separator",staticStyle:{"height":"2px","background":"rgb(138 138 138)","width":"60px","margin-left":"20px","margin-right":"20px"}}),_c('li',{style:(_vm.$store.state.stateProcess == 3
            ? { 'font-weight': 'bold', color: '#333' }
            : { 'font-weight': 'bold', color: 'rgb(167 167 167)' })},[_vm._v(" "+_vm._s(_vm.$t("app.t.text77"))+" ")])])]):_vm._e(),_c('div',{staticClass:"equis-cerrar",staticStyle:{"font-size":"25px","width":"53px","cursor":"pointer","padding-left":"18px","border-left":"1px solid #eee"},on:{"click":function($event){_vm.$route.name == 'createPropiedad'
  ? _vm.Regresar(1)
  : _vm.$route.name == 'createServicio'
    ? _vm.Regresar(3)
    : _vm.$route.name == 'updateServicio'
      ? _vm.Regresar(3)
      : _vm.$route.name == 'updatePropiedad'
        ? _vm.Regresar(1)
        : _vm.$route.name == 'checkinManual'
          ? _vm.Regresar(1)
          : _vm.$route.name == 'checkinPrevie'
            ? _vm.Regresar(11)
            : _vm.$route.name == 'checkinPrevieLink'
            ? _vm.Regresar(1)
            : _vm.$route.name == 'firstCreatePropiedad' 
            ? _vm.Regresar(4)
            : _vm.$route.name == 'manageChatBot' 
            ? _vm.Regresar(12)
            : _vm.Regresar(2)}}},[_c('i',{staticClass:"fal fa-times",staticStyle:{"cursor":"pointer"}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }