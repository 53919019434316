export const chatbot = {
   p1: `Set up your Virtual Assistant`,
   p2: `Activate the chatbot service to automatically respond to your guests' most common questions.`,
   p3: `Set up your Virtual Assistant. Customize your chatbot's responses to provide quick and accurate information to your guests. Automatically resolve their most frequent inquiries.`,
   p4: `Configure`,
   p5: `Virtual Assistant successfully activated`,
   p6: `Virtual Assistant deactivated`,

   p7: `Set up ChatBot responses`,
   p8: `Assigned accommodation:`,
   p9: `Customize the chatbot's automatic responses to efficiently address your guests' most frequent questions.`,

   p10: `You have unsaved changes. Do you want to discard them and switch questions?`,

   p11: `Question:`,
   p12: `Include an image in your response`,
   p13: `Upload image`,
   p14: `Delete Image`,
   p15: `Save Response`,
   p16: `Response saved successfully`,
   p17: `An error occurred while saving the response.`,

   p18: `An error occurred while trying to delete the image.`,
   p19: `Write your response...`,

   p20: `Response block`,
   p21: `Preview`,
   p22: ``,
   p23: ``,
   p24: ``,
   p25: ``,
   p26: ``,
   p27: ``,
   p28: ``,
   p29: ``,
   p30: ``,
}
