export const home_i18n = {
    text1: "Send your first chekin to sign",
    text2: "The virtual chekin collects the guest's biometric data and links it to his or her reservation.",
    text3: "Send Chekin",
    text4: 'Home',
    text5: "Check-in",
    text6: "Welcome",
    text7: "Configuration check in",
    text8: "my account",
    text9: "Configuration check in",
    text10: "Logout",
    text11: "Hotel image",
    text12: "Hotel policies",
    text13: "welcome messages",
    text14: "Upload your Hotel logo",
    text15: "Select or drag the logo",
    text16: "Cancel",
    text17: "Name of Hotel",
    text18: "Save changes",
    text19: "Change logo",
    text20: "Download",
    text21: "Signature history",
    text22: "Control panel of the documents signed by the guests.",
    text23: "Filter:",
    text24: "Disabled",
    text25: "By date",
    text26: "By country",
    text27: "Name",
    text28: "Surname",
    text29: "Nationality",
    text30: "Check-in date",
    text31: "Synchronized",
    text32: "Options",
    text33: "No data",
    text34: "Rows per page:",
    text35: "Search",
    text36: "Countries",
    text37: "From",
    text38: "To",
    text39: "COPYRIGHT © 2022 , All rights reserved WACheckines",
    text40: "Made with",
    text41: "Export",
    text42: "See detail",
    text43: "View signed PDF",
    text44: "Delete",
    text45: "Select an option",
    //aca
    text46: "Add a payment method",
    text47: "Register or update the payment method in which monthly charges will be made.",
    text48: "Total debt",
    text49: "Successful payment",

    p1: "You are on the trial version",
    p2: "You have",
    p3: "days left!",
    p4: "The trial period has ended.",
    p5: "Subscribe now",
    p6: "Your free trial has ended!",

};