export const chatbot = {
   p1:`Configura tu Asistente Virtual`,
   p2:`Activa el servicio de chatbot para responder automáticamente las preguntas más comunes de tus huéspedes`,
   p3:`Configura tu Asistente Virtual Personaliza las respuestas de tu chatbot para brindar información rápida y precisa a tus huéspedes. Resuelve sus dudas más comunes de forma automática.`,
   p4:`Configurar`,
   p5:`Asistente Virtual activado con éxito`,
   p6:`Asistente Virtual desactivado`,

   p7:`Configura las respuestas del ChatBot`,
   p8:`Alojamiento asignado:`,
   p9:`Personaliza las respuesta automáticas del chatbot para resolver las preguntas más frecuentes de tus huéspedes de forma eficiente.`,

   p10:`Tienes cambios sin guardar. ¿Deseas descartarlos y cambiar de pregunta?`,

   p11:`Pregunta:`,
   p12:`Incluye una imagen a tu respuesta`,
   p13:`Cargar imagen`,
   p14:`Eliminar Imagen`,
   p15:`Guardar Respuesta`,
   p16:`Respuesta guardada correctamente`,
   p17:`Ocurrió un error al guardar la respuesta.`,

   p18:`Ocurrió un error al intentar eliminar la imagen.`,
   p19:`Escribe tu respuesta...`,

   p20:`Bloque de respuesta`,
   p21:`Vista previa`,
   p22:``,
   p23:``,
   p24:``,
   p25:``,
   p26:``,
   p27:``,
   p28:``,
   p29:``,
   p30:``,

}