import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import stateCheckin from './checkinStore'; // Importa el módulo del checkin correctamente
import stateProperty from './propertyStore'; // Importa el módulo para las propiedades correctamente
import stateUser from './userStore'; // Importa el módulo para las user correctamente
import stateCheckinProgrammed from './programmedStore';
import stateChatBot from "./chatBotStore";


Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    checkin: stateCheckin,  
    property: stateProperty,
    user: stateUser,
    programmed: stateCheckinProgrammed,
    chatbot: stateChatBot,
  },
});
