import axios from 'axios';
import { dominio } from '../../config/base';
const url = dominio;

export default {
     
    async changeStateQuestion({ commit }, question){
      commit('SET_QUESTION', question);
    },

    async answerResponse({ commit }, payload){
      commit('SET_ANSWER', payload);
    },
    
    setUnsavedChanges({ commit }, status) {
      commit('SET_UNSAVED_CHANGES', status);
  },

  };
  