export const suscription = {
  p1: `Manage your current plan details and explore options to upgrade or change your subscription.`,
  p2: `Annual Plan`,
  p3: `Monthly Plan`,
  p4: `Active until`,
  p5: `Per room per month`,
  p6: `Per accommodation per month`,
  p7: `Occupancy number`,
  p8: `Per occupancy per month`,
  p9: `Update subscription`,
  p10: `Active services`,
  p11: `Active`,
  p12: `Not active`,
  p13: `Invite your guests to check-in via WhatsApp, scheduling it before their arrival at the accommodation.`,
  p14: `Reviews`,
  p15: `Invite your guests to rate their experience and enhance your accommodation's reputation with positive reviews.`,
  p16: `Remote access`,
  p17: `Register and manage rooms equipped with smart locks. Configure the access codes to be provided to guests.`,
  p18: `Identity verification`,
  p19: `We verify guests' identities by comparing the photo on their ID or passport with a real-time selfie.`,
  p20: `Manage subscription and payment methods`,
  p21: `Add or remove cards as needed`,
  p22: `Your current subscription is free`,
  p23: `Upgrade your subscription to access advanced features.`,
  p24: `Your free subscription expires in`,
  p25: `day`,
  p26: `days`,
  p27: `Don’t lose access to advanced features; upgrade your subscription now`,
  p28: `Customize your plan with additional services`,
  p29: `occupancy`,
  p30: `month`,
  p31: `Reviews`,
  p32: `Remote access`,
  p33: `tent`,
  p34: `villa`,
  p35: `Identity verification`,
  p36: `TOTAL OCCUPANCY`,
  p37: `Total occupancy refers to the maximum capacity of people allowed in all your Apartments, for example, (if you select 5 apartments, your total occupancy can be 20 spaces). Providing total occupancy helps us manage your subscription accurately and comply with safety regulations.`,
  p38: `Total occupancy refers to the maximum capacity of people allowed in your Villa, for example, (if you select 5 apartments, your total occupancy can be 20 spaces). Providing total occupancy helps us manage your subscription accurately and comply with safety regulations.`,
  p39: `Total occupancy refers to the maximum capacity of people allowed in your Hotel - Hostel, for example, (if you select 5 apartments, your total occupancy can be 20 spaces). Providing total occupancy helps us manage your subscription accurately and comply with safety regulations.`,
  p40: `Total occupancy refers to the maximum capacity of people allowed in your Camping site, for example, (if you select 5 apartments, your total occupancy can be 20 spaces). Providing total occupancy helps us manage your subscription accurately and comply with safety regulations.`,
  p41: `You must enter the total occupancy of your properties.`,
  p42: `WACheckin WhatsApp`,
  p43: `Your WhatsApp Business`,
  p44: `per occupancy/month`,
  p45: `per room/month`,
  p46: `Annual`,
  p47: `Activate subscription`,
  p48: `Update subscription`,
  p49: `Selected subscription`,
  p50: `Number of`,
  p51: `room`,
  p52: `accommodation`,
  p53: `Monthly price per`,
  p54: `rooms`,
  p55: `accommodations`,
  p56: `Selected occupancy number`,
  p57: `Monthly price per`,
  p58: `occupancy`,
  p59: `Subscription type`,
  p60: `Annual`,
  p61: `Monthly`,
  p62: `Additional services:`,
  p63: `No services added`,
  p64: `Adding the WhatsApp service is recommended`,
  p65: `Reviews`,
  p66: `Total to pay`,
  p67: `Proceed with payment`,
  p68: `Update Subscription`,
  p69: "شات بوت",
  
  p70: `Subscription`,
  p71: `Active`,
  p72: `Optional additional services`,

  p73: `Do you have new properties to manage?`,

  p74: `Details of your update and new services`,
  p75: `The charge for additional services or properties will be applied proportionally, taking into account the remaining days of the month in your current subscription.`,
  p76: `This ensures you only pay for the exact usage time until your plan's renewal.`,

  p77: `Added properties`,
  p78: `Additional services`,
  p79: `Remote access`,
  p80: `ChatBot AI`,
  p81: `Price`,
  p82: `Annual`,
  p83: `Monthly`,
  p84: `Update subscription`,

  p85: `Subscribed properties`,
  p86: `Additional services`,
  p87: `No additional services contracted`,
  p88: `ChatBot AI`,
  p89: `Remote access`,

  p90: `Included in the plan`,

  p91: `Price per year`,
  p92: `Price per month`,

  p93: `Status`,
  p94: `Active`,
  p95: `Expires`,

  p96: `Register and manage rooms equipped with smart locks. Set up the access codes that will be provided to guests.`,

  p97: `ChatBot AI`,
  p98: `Add the AI-powered chatbot service and automate your guests' inquiries quickly and efficiently.`,
  
  p99: `Are you sure you want to continue with the process?`,
  p100: `Update subscription`,

  p101: ``,
  p102: ``,
  p103: ``,
  p104: ``,
  p105: ``,
  p106: ``,
  p107: ``,
  p108: ``,
  p109: ``,
  p110: ``,
  p111: ``,
  p112: ``,
  p113: ``,
  p114: ``,
  p115: ``,
};
