<script>
import Dialog from 'primevue/dialog';

   export default {
     name: "modalLimitExceeded",
     components: { Dialog },
     data() {
        return {

        }
     },
     computed:{
        user(){
            return this.$store.state.userRoot;
        }
     },
     methods:{
        closeBasic() {
            this.$parent.visibleModalBasic = false;
        },
        goRoute() {
            this.$parent.visibleModalBasic = false;
            if(this.user.status_subscription == "demo" || this.user.status_subscription == "demo_expired"){
                this.$router.push({name: "InvoicesCompra"});
            }else {
                this.$router.push({name: "updateSubscription"});
            }
            
        }
     }
   }
</script>
<template>
    <div class="modal-limit-exceeded">
        <Dialog header="Header " :visible.sync="$parent.visibleModalBasic" :containerStyle="{width: '50vw'}" :modal="true" @hide="closeBasic">
            <template #header>
                <h3></h3>
            </template>

            <div class="containerModal">
                 <div class="image-panda">
                      <img src="/img/marca/panda11.svg" alt="planda-plan" class="img-fluid" style="max-width:200px;">
                 </div>
                 <div class="text-description">
                    <p><strong style="font-size: 32px; font-weight: 700;">{{ $t('empresa.propiedades.p94') }}</strong></p>
                    <p style="font-size: 17px;font-weight: 400;">{{ $t('empresa.propiedades.p95') }}</p>
                    <p style="font-size: 17px;font-weight: 400;">{{ $t('empresa.propiedades.p96') }}</p>
                    <button @click.prevent="goRoute()" class="btn btn-success" style="margin-top: 14px; font-size: 19px;">{{ $t('empresa.propiedades.p97') }}</button>
                </div>
            </div>
        </Dialog>
    </div>
</template>
<style lang="scss" scoped>
</style>