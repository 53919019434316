var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.demo &&
    _vm.user &&
    _vm.user?.servicios &&
    !_vm.user?.suscription_id_stripe &&
    _vm.property
  )?_c('div',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('slideInDown'),expression:"'slideInDown'"}],staticClass:"alertDemo d-flex"},[_c('span',{},[(_vm.calculateRemainingDays(_vm.user?.servicios?.created_at) > 0)?[_c('strong',{staticStyle:{"font-size":"15px","color":"#000"}},[_vm._v(_vm._s(_vm.$t("empresa.home.p1")))]),_c('strong',{staticStyle:{"font-size":"15px","color":"#fff","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.$t("empresa.home.p2"))+" ")]),_c('strong',{staticStyle:{"font-size":"15px","color":"#fff","text-transform":"uppercase"}},[_vm._v(_vm._s(_vm.calculateRemainingDays(_vm.user?.servicios?.created_at)))]),_c('strong',{staticStyle:{"font-size":"15px","color":"#fff","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.$t("empresa.home.p3"))+" ")]),_c('button',{staticClass:"btn btn-success",staticStyle:{"font-size":"15px","color":"rgb(255, 255, 255)","cursor":"pointer","padding":"0","padding-inline":"14px","margin-left":"10px","border-radius":"10px","margin-bottom":"0"},style:(_vm.$isMobile?{'margin-top': '3px'}: {}),on:{"click":function($event){$event.preventDefault();return _vm.goRoute()}}},[_vm._v(" "+_vm._s(_vm.$t("empresa.home.p5"))+" ")])]:(_vm.calculateRemainingDays(_vm.user?.servicios?.created_at) <= 0)?[_c('strong',{staticStyle:{"font-size":"15px","color":"#000"}},[_vm._v(_vm._s(_vm.$t("empresa.home.p6")))]),_c('button',{staticClass:"btn btn-success",staticStyle:{"font-size":"15px","color":"rgb(255, 255, 255)","cursor":"pointer","padding":"0","padding-inline":"14px","margin-left":"10px","border-radius":"10px","margin-bottom":"0"},style:(_vm.$isMobile?{'margin-top': '3px'}: {}),on:{"click":function($event){$event.preventDefault();return _vm.goRoute()}}},[_vm._v(" "+_vm._s(_vm.$t("empresa.home.p5"))+" ")])]:_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }