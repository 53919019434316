// Funcion para calcular y seleccionar el precio correspondiente de cada plan 
export const validateSelectPricing = (
  typeProperty,
  propertrys,
  type,
  precios
) => {
  if (type === "monthly") { // MENSUAL
    if (typeProperty === "Hotel - Hostal" || typeProperty === "Camping") {
      // Precios específicos para hoteles/hostales
      if (propertrys >= 1 && propertrys <= 20) {
        return precios.monthly;
      } else if (propertrys > 20 && propertrys <= 50) {
        return precios.monthly_range_two;
      } else if (propertrys > 50 && propertrys <= 100) {
        return precios.monthly_range_three;
      } else if (propertrys > 100 && propertrys <= 150) {
        return precios.monthly_range_four;
      } else if (propertrys > 150) {
        return precios.monthly_range_five;
      }
    } else if (
      typeProperty === "Apartamento turístico" ||
      typeProperty === "Villa"
    ) {
      if (propertrys >= 1 && propertrys <= 4) {
        console.log("1");
        return precios.monthly;
      } else if (propertrys >= 5 && propertrys <= 10) {
        console.log("2" + precios.monthly_range_two);
        return precios.monthly_range_two;
      } else if (propertrys > 10) {
        console.log("3");
        return precios.monthly_range_three;
      }
    }
  } else if (type === "annual") {  // ANUAL
    if (typeProperty === "Hotel - Hostal" || typeProperty === "Camping") {
      // Precios específicos para hoteles/hostales
      console.log("Precios específicos para hoteles/hostales")
      if (propertrys >= 1 && propertrys <= 20) {
        return precios.annual;
      } else if (propertrys > 20 && propertrys <= 50) {
        return precios.annual_range_two;
      } else if (propertrys > 50 && propertrys <= 100) {
        return precios.annual_range_three;
      } else if (propertrys > 100 && propertrys <= 150) {
        return precios.annual_range_four;
      } else if (propertrys > 150) {
        return precios.annual_range_five;
      }
    } else if (
      typeProperty === "Apartamento turístico" ||
      typeProperty === "Villa"
    ) {
      if (propertrys >= 1 && propertrys <= 4) {
        return precios.annual;
      } else if (propertrys >= 5 && propertrys <= 10) {
        return precios.annual_range_two;
      } else if (propertrys > 10) {
        return precios.annual_range_three;
      }
    }
  }
};

export const calcularAlojamiento = (
  basicPriceInitial,
  contador3,
  precioPlazaBase,
  plazas,
  typeRango
) => {
  console.log(precioPlazaBase, 'precioPlazaBase')

  var precioBase = basicPriceInitial * contador3;
  // var plazasBase = precioPlazaBase * plazas;
  var total = precioBase;
  var result;

  if (typeRango) {
    result = (total * 12) / Number(contador3);
  } else {
    result = Number(total) / Number(contador3);
  }

  return result;
};
