<template>
  <nav class="navbar navbar-default fixed-top" style="justify-content: right !important">
    <div class="container" style="padding-right: 7.6%; justify-content: right !important">
      <div class="logo">
        <img v-animate-css="'slideInLeft'" src="/img/1x/logo1.png" alt="" class="img-fluid"
          style="width: 132px; position: absolute; top: 9px; left: 8%" />
      </div> 

      <div class="pasos lg" v-if="$route.name == 'checkinManual'" style="
          position: fixed;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
          width: 40%;
        ">
        <ul class="d-flex flex-row" style="
          
            margin: auto;
            text-align: center;
            align-items: center;
            justify-content: center;
          ">
          <li :class="{ 'cursor': $store.state.stateProcess == 2 }" @click.prevent="menuActive(1)" :style="
            $store.state.stateProcess == 1
              ? { 'font-weight': 'bold', color: '#333' }
              : { 'font-weight': 'bold', color: 'rgb(167 167 167)' }
          ">
            {{ $t("app.t.text75") }}
          </li>
          <div class="line-separator" style="
              height: 2px;
              background: rgb(138 138 138);
              width: 60px;
              margin-left: 20px;
              margin-right: 20px;
            "></div>
          <li :style="
            $store.state.stateProcess == 2
              ? { 'font-weight': 'bold', color: '#333' }
              : { 'font-weight': 'bold', color: 'rgb(167 167 167)' }
          ">
            {{ $t("app.t.text76") }}
          </li>

          <div class="line-separator" style="
              height: 2px;
              background: rgb(138 138 138);
              width: 60px;
              margin-left: 20px;
              margin-right: 20px;
            "></div>
          <li :style="
            $store.state.stateProcess == 3
              ? { 'font-weight': 'bold', color: '#333' }
              : { 'font-weight': 'bold', color: 'rgb(167 167 167)' }
          ">
            {{ $t("app.t.text77") }}
          </li>
        </ul>
      </div>

      <div class="equis-cerrar" @click="
  $route.name == 'createPropiedad'
    ? Regresar(1)
    : $route.name == 'createServicio'
      ? Regresar(3)
      : $route.name == 'updateServicio'
        ? Regresar(3)
        : $route.name == 'updatePropiedad'
          ? Regresar(1)
          : $route.name == 'checkinManual'
            ? Regresar(1)
            : $route.name == 'checkinPrevie'
              ? Regresar(11)
              : $route.name == 'checkinPrevieLink'
              ? Regresar(1)
              : $route.name == 'firstCreatePropiedad' 
              ? Regresar(4)
              : $route.name == 'manageChatBot' 
              ? Regresar(12)
              : Regresar(2)
      " style="
          font-size: 25px;
          width: 53px;
          cursor: pointer;
          padding-left: 18px;
          border-left: 1px solid #eee;
        ">
        <i class="fal fa-times" style=" cursor: pointer;"></i>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  mounted() {
    console.log("nav component");
    console.log(this.$route.name);
  },
  methods: {

    menuActive(numer) {
      if (this.$store.state.stateProcess == 2) {
        this.$store.state.stateProcess = 1;
      }
    },

    logout() {
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          window.location.href = "/";
        })
        .catch((error) => {
          toastr.error(this.$t("app.t.text79"));
        });
    },

    Regresar(numer) {
      if (numer == 1) {
        this.$router.push({ name: "Bookings" });
      } else if (numer == 3) {
        this.$router.push({ name: "Ofertas" });
      } else if (numer == 11) {
        this.$router.push({ name: "Reservas" });
      }else if(numer == 12){
        this.$router.back();
      } else if (numer == 4) {
        if(this.$route.query.create_user){
          this.$router.back();
        }else {
          this.$swal({
          title: this.$t("app.t.text80"),
          text: this.$t("app.t.text81"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "gray",
          confirmButtonColor: "#c00202",
          confirmButtonText: this.$t("app.t.text82"),
          cancelButtonText: this.$t("app.t.text83"),
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.state.loader = true;
            this.logout();
          }
        });
        }
      } else {
        this.$swal({
          title: this.$t("app.t.text80"),
          text: this.$t("app.t.text81"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "gray",
          confirmButtonColor: "#c00202",
          confirmButtonText: this.$t("app.t.text82"),
          cancelButtonText: this.$t("app.t.text83"),
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.state.loader = true;
            this.logout();
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  background: white;
  height: 78px;
  border-bottom: 1px solid #eee;
}
</style>
