import stateChatBot from './stateChatBot';
import mutations from './mutations';
import actions from './actions';

export default {
  state: stateChatBot,
  mutations,
  actions,
  namespaced: true
};
