import axios from "axios";
import { dominio } from "./base";
import state from "../store/state";
import moment from "moment";
import { calcularAlojamiento } from '@/utils/suscriptions/priceCalculations';


export const  calculationsAlojamiento = (basicPriceInitial, contador3, precioPlazaBase, plazas, typeRango, pais) => {
    console.log(basicPriceInitial, 'calculationsAlojamiento')
    var calculo =  calcularAlojamiento(basicPriceInitial, contador3, precioPlazaBase, plazas, typeRango,);
    var result = formatearPrecio(calculo, pais);
    return result;

}

export function errorServer(error){
  var errors = error.response.data.data;
  $.each(errors, function(i) {
      toastr.error(errors[i]);
  });
}

export async function propertySelect() {
  try {
    const url = `${dominio}/user/data`;
    const response = await axios.get(url);
    let propiedadActiva = null;
    const propiedadStorage = localStorage.getItem("propiedadActiva");
    if (propiedadStorage !== null) {
      propiedadActiva = JSON.parse(propiedadStorage);
    } else {
      propiedadActiva = response.data.propiedad?.id;
      localStorage.setItem("propiedadActiva", propiedadActiva);
    }

    const datos = await selectPropertyNav(propiedadActiva);
    return datos;
  } catch (error) {
    console.log(error);
    throw error;
  }
}


export async function selectPropertyNav(propiedadActiva) {
  try {
    const url = `${dominio}/propiedad`;
    const datos = { propiedad_id: propiedadActiva };
    const response = await axios.post(url, datos);
    return response.data;
  } catch (error) {
    console.log('Error', error);
    throw error;
  }
}


export function verificarUser() {
  let user = JSON.parse(localStorage.getItem("user"));
  let url = dominio + "/user";

  axios.get(url).then((res) => {
    if (res.data.restaday !== null && res.data.restaday !== "") {
      user.restaday = res.data.restaday;
      state.user.restaday = res.data.restaday;
    }
    if (res.data.paymentfail !== null && res.data.paymentfail !== "") {
      user.paymentfail = res.data.paymentfail;
      state.user.paymentfail = res.data.paymentfail;
      state.user.suscription_id_stripe = res.data.suscription_id_stripe;
      user.suscription_id_stripe = res.data.suscription_id_stripe;
    }

    // Guardar el objeto actualizado en el storage
    localStorage.setItem("user", JSON.stringify(user));
  });
}

export function listaConsumo() {
  let url = dominio + "/balance/consumo";
  axios
    .post(url)
    .then((res) => {
      state.consumo = res.data.consumo;
      state.checkins = res.data.checkins;
    })
    .catch((e) => {});
}

export function listarUser() {
  let url = dominio + "/user";
  console.log("user ---");
  axios.get(url).then((res) => {
    console.log(res.data, "user ---");
    return res.data;
  });
}

export function Notifications() {
  let url = dominio + "/notificatios/list";
  axios
    .post(url)
    .then((res) => {
      state.notificaciones = res.data;
    })
    .catch((e) => {
      console.log(`error al cargar las notificaciones`);
    });
}

export function verificarSuscription() {
  console.log("Verific state suscription");
  let url = dominio + "/user";
  axios.get(url).then((res) => {
    state.user = res.data;
    const itemStr = localStorage.getItem("user");
    console.log("itemStr:", itemStr);
    const itemData = JSON.parse(itemStr);
    itemData.paymentfail = res.data.paymentfail;
    console.log("itemData:", itemData);
    const nuevoItemStr = JSON.stringify(itemData);
    console.log("nuevoItemStr:", nuevoItemStr);
    var resuldatos = localStorage.setItem("user", nuevoItemStr);
  });
}

export function modalIdiomas() {
  $("#modalLenguaje").modal("show");
  // state.efecto = true;
}

export function modalIdiomasApp() {
  $("#modalLenguaje").modal("show");
  state.efecto = true;
}

export function modalDatosGeneral(id) {
  $("#modalDatosGeneral").modal("show");
  state.efecto = true;
  const datos = {
    id: id,
  };
  let url = dominio + "/information/data/checkin";
  axios
    .post(url, datos)
    .then((res) => {
      state.datosModal = res.data;
      state.efecto = false;
    })
    .catch((e) => {
      state.efecto = false;
      toastr.error(`Error, no se pudo obtener los datos de la notificación`);
    });
}

// Calcular dias disponibles de la version free
export function diasRestantes(creado) {
  const startDate = moment(creado); // Fecha de inicio de la suscripción
  const subscriptionDays = 14; // Número de días de la suscripción
  const now = moment();
  const diffDays = now.diff(startDate, "days");
  return subscriptionDays - diffDays;
}



//Funciones para  Formatear precio
export function formatearPrecio(precio, pais) {
  precio = Number(precio);
  if (isNaN(precio)) {
    return precio;
  }

  if (pais === 'Colombia') {
    // Formato para Colombia sin decimales
    return precio.toFixed(0).replace(/\d(?=(\d{3})+(?!\d))/g, "$&.").replace(',', '.');
  } else if (pais === 'España') {
    // Formato para España con dos decimales
    return precio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&.").replace(',', '.');
  } else {
    // Formato por defecto si el país no es especificado
    return precio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&.").replace(',', '.');
  }
}


export function formatearPrecioGeneral(value) {
  value = Number(value);
  if (isNaN(value)) {
    return value;
  }
  let val = (value / 1).toFixed(2).replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}


export function activarAudio() {
  $("#audio").prop("muted", true);
  $("#audio")[0].play();
}
