<script>
import { mapState } from "vuex";
export default {
  name: "messageAccountDemo",
  computed: {
    ...mapState("user", ["user"]),
    ...mapState(["demo"]),
    ...mapState("property", ["property"]),
  },
  methods: {
    removeMessage() {
      this.$store.state.demo = false;
    },

    calculateRemainingDays(fecha) {
      if(this.$store.state.userRoot.servicios && !this.$store.state.userRoot.servicios.state_demo){
              return 0; // Demo deshabilitado
      }
      const created_at = this.moment(fecha); // fecha de registro
      const current_date = this.moment(); // fecha actual
      const days_elapsed = current_date.diff(created_at, "days"); // dias transcurridos
      const remaining_days = 14 - days_elapsed;
      return remaining_days; // dias restantes
    },
    goRoute() {
      if (this.$route.name != "InvoicesCompra") {
        this.$router.push({ name: "InvoicesCompra" });
      }
    },
  },
};
</script>

<template>
  <div
    v-if="
      demo &&
      user &&
      user?.servicios &&
      !user?.suscription_id_stripe &&
      property
    "
    class="alertDemo d-flex"
    v-animate-css="'slideInDown'"
    style=""
  >
    <span style=""
      ><template v-if="calculateRemainingDays(user?.servicios?.created_at) > 0">
        <strong style="font-size: 15px; color: #000">{{
          $t("empresa.home.p1")
        }}</strong>
        <strong style="font-size: 15px; color: #fff; text-transform: uppercase">
          {{ $t("empresa.home.p2") }}
        </strong>

        <strong
          style="font-size: 15px; color: #fff; text-transform: uppercase"
          >{{ calculateRemainingDays(user?.servicios?.created_at) }}</strong
        >
        <strong style="font-size: 15px; color: #fff; text-transform: uppercase">
          {{ $t("empresa.home.p3") }}
        </strong>

        <button
          class="btn btn-success"
          style="
            font-size: 15px;
            color: rgb(255, 255, 255);
            cursor: pointer;
            padding: 0;
            padding-inline: 14px;
            margin-left: 10px;
            border-radius: 10px;
            margin-bottom: 0;
          "
          :style="$isMobile?{'margin-top': '3px'}: {}"
          @click.prevent="goRoute()"
        >
          {{ $t("empresa.home.p5") }}
        </button>
      </template>
      <template
        v-else-if="calculateRemainingDays(user?.servicios?.created_at) <= 0"
      >
        <strong style="font-size: 15px; color: #000">{{
          $t("empresa.home.p6")
        }}</strong>
        <button
          class="btn btn-success"
          style="
            font-size: 15px;
            color: rgb(255, 255, 255);
            cursor: pointer;
            padding: 0;
            padding-inline: 14px;
            margin-left: 10px;
            border-radius: 10px;
            margin-bottom: 0;
          "
          :style="$isMobile?{'margin-top': '3px'}: {}"
          @click.prevent="goRoute()"
        >
          {{ $t("empresa.home.p5") }}
        </button>
      </template></span
    >
  </div>
</template>

<style lang="scss" scoped>
@media(max-width:768px){ 
  .alertDemo {
    position: relative;
    background: #e4c43c;
    color: #dedede;
    padding-top: 6px;
    padding-right: 6px;
    padding-bottom: 6px;
    top: -60px;
    left: 0px;
    position: absolute;
    right: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 64px;
}
}
@media(min-width: 768px){  //escritorio
  .alertDemo {
  position: relative;
  background: #e4c43c;
  color: #dedede;
  padding-top: 6px;
  padding-right: 6px;
  padding-bottom: 6px;
  padding-left: 121px !important;
  top: -36px;
  left: 0px;
  position: absolute;
  right: 0px;
  height: 35px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

}


</style>
