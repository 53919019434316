export default {
   SET_QUESTION(state, question){
       console.log(question, "Anterior");
       state.question = question;
   },
   SET_UNSAVED_CHANGES(state, status) {
    state.unsavedChanges = status;
   },
   SET_CHANGES_QUESTION(state, status) {
    console.log('SET_CHANGES_QUESTION', status);
    state.activeFechQuestion = status;
   },

   SET_ANSWER(state, payload) {
    // Encuentra la pregunta activa y actualiza su respuesta
    if (state.question.id === payload.id) {
      state.question.answer = payload.answer;
    }
  },

  };