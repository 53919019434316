export const maqueta = {
    text1: "Se han realizado",
    text2: "Check-in",
    text3: "hojas de papel salvadas 😊",
    text4: "Cada 100 Check-in plantamos un árbol con",
    text5: "ONE TREE PLANTED",
    text6: "Notificaciones",
    text7: "No tienes notificaciones",
    text8: "Ver todas",
    text9: "Bienvenido",
    text10: "Empresa",
    text11: "Todos los alojamientos",
    text12: "Perfil",
    text13: "Cerrar sesión",
    text14: "Lo sentimos, se ha producido un error al cerrar la sesión",
    text15: "El pago de su suscripción fue rechazado",
    text16: "Para continuar utilizando WACheckindebe ir a realizar su pago",
    text17: "Información del check-in.",
    text18: "Datos de check-in",
    text19: "Fecha de la solicitud:",
    text20: " a las",
    text21: "Datos recolectados en el check-in del huésped",
    text22: "Nombre",
    text23: "Apellido",
    text24: "Teléfono",
    text25: "Fecha de hospedaje",
    text26: "Tipo de documento",
    text27: "Pasaporte",
    text28: "Documento nacional de identidad",
    text29: "Número de documento",
    text30: "Información adicional",
    text31: "Expiración de documento",
    text32: "Nacionalidad",
    text33: "Fecha de nacimiento",
    text34: "Genero",
    text35: "Femenino",
    text36: "Masculino",
    text37: "Cerrar",
    text38: "Check-Ins",
    text39: "Peticiones de servicios",
    text40: "Alojamientos",
    text41: "Ventas cruzadas",
    text42: "Integraciones",
    text43: "Facturación",

    // Correcciones 
    p1: "Check-ins programados (Reservas)",


    // Corecciones   validaciones de plan
    p2:`Actualiza tu plan para poder realizar check-ins`,
    p3:`Disculpe, actualmente no cuenta con ninguna suscripción activa ni créditos para
    su alojamiento.`,
    p4:`Actualizar plan`,


    p5:`Los checkin no están disponibles en este alojamiento.`,
    p6:`Para mas información puede comunicarse con el propietario del alojamiento.`,

    p7:`Cuenta no verificada`,
    p8:`Por favor revisa tu bandeja de entrada y confirme su cuenta. ¿No te llego el
    correo electrónico?`,
    p9:`Reenviar`,
    p10:`Enviando correo electrónico, por favor espere...`,
    p11:`Mi suscripción`,


    p12:`Idioma y región`,
    p13:`Elige un idioma y una región`,
    p14:`Aquí podrás ver todos los check-ins realizados.`,
    p15:`Programa tus check-ins con anticipación y envíalos a tus huéspedes.`,
    p16:`Aquí se muestran las solicitudes de servicios adicionales de tus huéspedes, como alquileres, tours y más.`,
    p17:`WhatsApps masivos`,
    p18:`Conecta tu cuenta de WhatsApp Business a nuestra plataforma y habilita el envío masivo de mensajes personalizados a tus huéspedes de manera rápida y sencilla, directamente desde tu número oficial.`,
    p19:`Esta función aún se encuentra en desarrollo.`,
    p20:`Información del check-in`,
    
    p21:`Información del check-in`,
    p22:`Check-ins asociados`,
    p23:`No hay check-in asociados.`,
    p24:`Más información:`,
    p25:``,
    p26:``,
    p27:``,
    p28:``,
    p29:``,
    p30:``,
    p31:``,

    
};